import { useContext } from "react";
import { ThemeContext } from "../../utils/themeContext";
import {
  ArrowDownIcon,
  BoxLink,
  Container,
  Content,
  Description,
  DescriptionBox,
  TextLink,
  Title,
  TitleBox,
} from "./about.styles";

const About = () => {
  const { isLightMode } = useContext(ThemeContext);
  return (
    <Container>
      <Content>
        <TitleBox isLightMode={isLightMode}>
          <Title isLightMode={isLightMode}>About</Title>
        </TitleBox>
        <DescriptionBox>
          <Description isLightMode={isLightMode}>
            Hi! I'm Jacob Jiménez, although I'm known as Jayco. I'm a full stack
            developer with more than 5 years of experience turning ideas into
            digital solutions. I mix frontend and backend skills to build fast
            and cool web applications. I love facing technological challenges
            and creating amazing user experiences, ready to give your project a
            boost and make it shine!
          </Description>
        </DescriptionBox>
        <BoxLink>
          <TextLink
            isLightMode={isLightMode}
            onClick={() => {
              window.open(
                `${process.env.PUBLIC_URL}/docs/Resume-Jacobo-Jiménez.pdf`,
                "_blank"
              );
            }}
          >
            <ArrowDownIcon isLightMode={isLightMode} />
            Resume
          </TextLink>
        </BoxLink>
      </Content>
    </Container>
  );
};

export default About;
