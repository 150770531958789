import { useContext } from "react";
import {
  ArrowOutIcon,
  Container,
  Content,
  DescriptionBox,
  MailBox,
  MailTitle,
  SocialBox,
  SocialLinks,
  SocialTitle,
  TextLink,
  Title,
  TitleBox,
} from "./contact.styles";
import { ThemeContext } from "../../utils/themeContext";

const Contact = () => {
  const { isLightMode } = useContext(ThemeContext);
  return (
    <Container>
      <Content>
        <TitleBox isLightMode={isLightMode}>
          <Title isLightMode={isLightMode}>Contact</Title>
        </TitleBox>
        <DescriptionBox>
          <MailBox>
            <MailTitle isLightMode={isLightMode}>Mail</MailTitle>
            <TextLink
              isLightMode={isLightMode}
              href="mailto:jacobojimenez08@gmail.com"
              target="_blank"
            >
              <ArrowOutIcon isLightMode={isLightMode} />{" "}
              jacobojimenez08@gmail.com
            </TextLink>
          </MailBox>
          <SocialBox>
            <SocialTitle isLightMode={isLightMode}>Social medias</SocialTitle>
            <SocialLinks>
              <TextLink
                isLightMode={isLightMode}
                href="https://github.com/ImJac0b"
                target="_blank"
              >
                <ArrowOutIcon isLightMode={isLightMode} /> github
              </TextLink>
              <TextLink
                isLightMode={isLightMode}
                href="https://www.linkedin.com/in/dev-jacobojimenez/"
                target="_blank"
              >
                <ArrowOutIcon isLightMode={isLightMode} /> Linkedin
              </TextLink>
            </SocialLinks>
          </SocialBox>
        </DescriptionBox>
      </Content>
    </Container>
  );
};

export default Contact;
