import { useNavigate } from "react-router-dom";
import {
  BoxItems,
  BoxLogo,
  DarkIcon,
  DevIcon,
  Item,
  LightIcon,
  MainContainer,
  MainHeader,
  MenuActiveIcon,
  MenuIcon,
} from "./header.styles";
import { useContext } from "react";
import { ThemeContext } from "../../utils/themeContext";

const Header = ({
  isMenuOpen,
  setIsMenuOpen,
}: {
  isMenuOpen: boolean;
  setIsMenuOpen: (isOpen: boolean) => void;
}) => {
  const navigate = useNavigate();
  const { isLightMode, toggleLightMode } = useContext(ThemeContext);

  const handleNavigation = (path: string) => {
    navigate(path);
    setIsMenuOpen(false); // Close the menu
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <MainHeader>
      <MainContainer>
        <BoxLogo>
          <Item isLightMode={isLightMode} onClick={() => handleNavigation("/")}>
            <DevIcon isLightMode={isLightMode} />
          </Item>
        </BoxLogo>
        <BoxItems>
          <Item isLightMode={isLightMode} size="small">
            ES
          </Item>
          <Item isLightMode={isLightMode} size="small">
            {isLightMode ? (
              <DarkIcon isLightMode={isLightMode} onClick={toggleLightMode} />
            ) : (
              <LightIcon isLightMode={isLightMode} onClick={toggleLightMode} />
            )}
          </Item>
          <Item isLightMode={isLightMode} onClick={toggleMenu}>
            {!isMenuOpen ? (
              <MenuIcon isLightMode={isLightMode} />
            ) : (
              <MenuActiveIcon isLightMode={isLightMode} />
            )}
          </Item>
        </BoxItems>
      </MainContainer>
    </MainHeader>
  );
};

export default Header;
