import { useNavigate } from "react-router-dom";
import {
  ArrowRigthIcon,
  BoxContainer,
  BoxLinks,
  BoxRoles,
  BoxText,
  TextLinks,
  TextRoles,
  TitleName,
  TitleNameActive,
  TitleNickName,
  TitleNickNameActive,
} from "./home.styles";
import { useContext } from "react";
import { ThemeContext } from "../../utils/themeContext";

const Home = () => {
  const navigate = useNavigate();
  const { isLightMode } = useContext(ThemeContext);
  return (
    <>
      <BoxContainer>
        <BoxText>
          <TitleName isLightMode={isLightMode} variant="h3">
            Hey, I’m
            <TitleNameActive isLightMode={isLightMode} variant="h3">
              Jacob Jiménez
            </TitleNameActive>
          </TitleName>
          <TitleNickName isLightMode={isLightMode} variant="h3">
            But you can call me
            <TitleNickNameActive isLightMode={isLightMode} variant="h3">
              Jayco
            </TitleNickNameActive>
          </TitleNickName>
          <BoxRoles>
            <TextRoles isLightMode={isLightMode}>
              I’m a software analyst, UX/UI designer
            </TextRoles>
            <TextRoles isLightMode={isLightMode}>
              & full stack developer
            </TextRoles>
          </BoxRoles>
          <BoxLinks>
            <TextLinks
              isLightMode={isLightMode}
              onClick={() => {
                navigate("/");
              }}
              disabled
            >
              <ArrowRigthIcon isLightMode={isLightMode} /> See my projects
            </TextLinks>
            <TextLinks
              disabled={false}
              isLightMode={isLightMode}
              onClick={() => {
                navigate("/about");
              }}
            >
              <ArrowRigthIcon isLightMode={isLightMode} /> More about me
            </TextLinks>
          </BoxLinks>
        </BoxText>
      </BoxContainer>
    </>
  );
};

export default Home;
