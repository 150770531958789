import styled from "@emotion/styled";
import { Box, IconButton } from "@mui/material";
import CodeIcon from "@mui/icons-material/Code";
import LightModeIcon from "@mui/icons-material/LightMode";
import GridViewIcon from "@mui/icons-material/GridView";
import GridViewSharpIcon from "@mui/icons-material/GridViewSharp";
import DarkModeIcon from "@mui/icons-material/DarkMode";

export const MainHeader = styled("header")`
  height: 48px;
  display: flex;
  position: fixed;
  z-index: 5;
  top: 96px;
  left: 0;
  right: 0;
  pointer-events: none;

  @media (max-width: 540px) and (max-height: 720px) {
    top: 24px;
  }

  @media (max-width: 430px) and (max-height: 932px),
    @media (max-width: 412px) and (max-height: 915px),
    @media (max-width: 412px) and (max-height: 914px) {
    top: 24px;
  }
`;

export const MainContainer = styled(Box)`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px 90px;

  @media (max-width: 853px) and (max-height: 1280px) {
    padding: 0px 38px;
  }

  @media (max-width: 540px) and (max-height: 720px) {
    padding: 0px 24px;
  }

  @media (max-width: 430px) and (max-height: 932px),
    @media (max-width: 412px) and (max-height: 915px),
    @media (max-width: 412px) and (max-height: 914px) {
    padding: 0 24px 0 24px;
  }
`;

export const BoxLogo = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const BoxItems = styled(Box)`
  display: flex;
  gap: 20px;
  align-items: center;
`;

export const Item = styled(IconButton)<{
  isLightMode: boolean;
}>`
  && {
    color: #f3f2f9;
    color: ${(props) => props.isLightMode && "#606887"};
    text-transform: uppercase;
    font-family: "Montserrat", sans-serif;
    font-weight: 800;
    padding: 0px;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
    pointer-events: all;

    animation: fadeInFromTop 2s ease-in-out;
    animation-fill-mode: backwards;

    @keyframes fadeInFromTop {
      0% {
        transform: translateY(-100%);
        opacity: 0;
      }
      100% {
        transform: translateY(0);
        opacity: 1;
      }
    }

    @media (max-width: 540px) and (max-height: 720px) {
      font-size: 1rem;
      font-weight: 500;
    }

    @media (max-width: 430px) and (max-height: 932px),
      @media (max-width: 412px) and (max-height: 915px),
      @media (max-width: 412px) and (max-height: 914px) {
      font-size: 1rem;
      font-weight: 500;
    }
  }
`;

export const DevIcon = styled(CodeIcon)<{
  isLightMode: boolean;
}>`
  && {
    width: 48px;
    height: 48px;
    color: #f3f2f9;
    color: ${(props) => props.isLightMode && "#606887"};
    pointer-events: all;

    @media (max-width: 540px) and (max-height: 720px) {
      font-size: 1rem;
      width: 32px;
      height: 32px;
    }

    @media (max-width: 430px) and (max-height: 932px),
      @media (max-width: 412px) and (max-height: 915px),
      @media (max-width: 412px) and (max-height: 914px) {
      font-size: 1rem;
      width: 32px;
      height: 32px;
    }
  }
`;

export const LightIcon = styled(LightModeIcon)<{
  isLightMode: boolean;
}>`
  && {
    color: #f3f2f9;
    color: ${(props) => props.isLightMode && "#606887"};
    pointer-events: all;

    @media (max-width: 540px) and (max-height: 720px) {
      font-size: 1rem;
    }

    @media (max-width: 430px) and (max-height: 932px),
      @media (max-width: 412px) and (max-height: 915px),
      @media (max-width: 412px) and (max-height: 914px) {
      font-size: 1rem;
    }
  }
`;

export const DarkIcon = styled(DarkModeIcon)<{
  isLightMode: boolean;
}>`
  && {
    color: #f3f2f9;
    color: ${(props) => props.isLightMode && "#606887"};
    pointer-events: all;

    @media (max-width: 540px) and (max-height: 720px) {
      font-size: 1rem;
    }

    @media (max-width: 430px) and (max-height: 932px),
      @media (max-width: 412px) and (max-height: 915px),
      @media (max-width: 412px) and (max-height: 914px) {
      font-size: 1rem;
    }
  }
`;

export const MenuIcon = styled(GridViewIcon)<{
  isLightMode: boolean;
}>`
  && {
    width: 48px;
    height: 48px;
    color: #f3f2f9;
    color: ${(props) => props.isLightMode && "#606887"};
    pointer-events: all;
    transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    animation: rotateIcon 0.5s cubic-bezier(0.4, 0, 0.2, 1);

    @media (max-width: 540px) and (max-height: 720px) {
      font-size: 1rem;
      width: 32px;
      height: 32px;
    }

    @media (max-width: 430px) and (max-height: 932px),
      @media (max-width: 412px) and (max-height: 915px),
      @media (max-width: 412px) and (max-height: 914px) {
      font-size: 1rem;
      width: 32px;
      height: 32px;
    }
  }

  @keyframes rotateIcon {
    0% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(90deg);
    }
    100% {
      transform: rotate(90deg);
    }
  }
`;

export const MenuActiveIcon = styled(GridViewSharpIcon)<{
  isLightMode: boolean;
}>`
  && {
    width: 48px;
    height: 48px;
    color: #f3f2f9;
    color: ${(props) => props.isLightMode && "#606887"};
    pointer-events: all;
    transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    animation: rotateIcon 0.5s cubic-bezier(0.4, 0, 0.2, 1);

    @media (max-width: 540px) and (max-height: 720px) {
      font-size: 1rem;
      width: 32px;
      height: 32px;
    }

    @media (max-width: 430px) and (max-height: 932px),
      @media (max-width: 412px) and (max-height: 915px),
      @media (max-width: 412px) and (max-height: 914px) {
      font-size: 1rem;
      width: 32px;
      height: 32px;
    }
  }

  @keyframes rotateIcon {
    0% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(90deg);
    }
    100% {
      transform: rotate(90deg);
    }
  }
`;

export const MenuContainer = styled(Box)``;
