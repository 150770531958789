import React, { useEffect, useRef, useState } from "react";

interface Bubble {
  position: { x: number; y: number };
  radius: number;
  xOff: number;
  yOff: number;
  distanceBetweenWaves: number;
  count: number;
  lines: Line[];
  popping: boolean;
  maxRotation: number;
  rotation: number;
  rotationDirection: string;
  render: () => void;
  resetPosition: () => void;
  pop: () => void;
  color: string;
}

interface Line {
  x: number;
  y: number;
  lineLength: number;
  popDistance: number;
  popDistanceReturn: number;
  inversePop: boolean;
  popping: boolean;
  render: () => void;
  resetValues: () => void;
  updateValues: () => void;
}

const BubbleBackground: React.FC = () => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [bubbles, setBubbles] = useState<Bubble[]>([]);
  const bubbleCount = 20;
  const bubbleSpeed = 1;
  const popLines = 6;
  const popDistance = 40;

  const createBubble = (): Bubble => {
    const colors = [
      "#D6F6B5",
      "#F4E0B5",
      "#93F1D0",
      "#80F3D2",
      "#A4B8F5",
      "#B5FBC7",
      "#F8D3C3",
      "#C9DDD1",
      "#96E4F0",
      "#DABFE3",
    ];

    const bubble: Bubble = {
      position: { x: 0, y: 0 },
      radius: 8 + Math.random() * 6,
      xOff: Math.random() * (canvasRef.current?.width ?? 0) - (8 + Math.random() * 6),
      yOff: Math.random() * (canvasRef.current?.height ?? 0),
      distanceBetweenWaves: 50 + Math.random() * 40,
      count: (canvasRef.current?.height ?? 0) + Math.random() * (canvasRef.current?.height ?? 0),
      lines: [],
      popping: false,
      maxRotation: 85,
      rotation: Math.floor(Math.random() * (85 - 85 * -1)) + 85 * -1,
      rotationDirection: "forward",
      color: colors[Math.floor(Math.random() * colors.length)],
      resetPosition() {
        this.position = { x: 0, y: 0 };
        this.radius = 8 + Math.random() * 6;
        this.xOff = Math.random() * (canvasRef.current?.width ?? 0) - this.radius;
        this.yOff = Math.random() * (canvasRef.current?.height ?? 0);
        this.distanceBetweenWaves = 50 + Math.random() * 40;
        this.count = (canvasRef.current?.height ?? 0) + this.yOff;
        this.popping = false;
      },
      pop() {
        this.popping = true;
        this.lines.forEach((line) => (line.popping = true));
      },
      render() {
        const ctx = canvasRef.current?.getContext("2d");
        if (!ctx) return;

        const gradient = ctx.createLinearGradient(-this.radius, 0, this.radius, 0);
        gradient.addColorStop(0, this.color);
        gradient.addColorStop(0.5, "#ffffff");
        gradient.addColorStop(1, this.color);

        if (this.rotationDirection === "forward") {
          if (this.rotation < this.maxRotation) {
            this.rotation++;
          } else {
            this.rotationDirection = "backward";
          }
        } else {
          if (this.rotation > this.maxRotation * -1) {
            this.rotation--;
          } else {
            this.rotationDirection = "forward";
          }
        }

        ctx.save();
        ctx.translate(this.position.x, this.position.y);
        ctx.rotate((this.rotation * Math.PI) / 180);

        ctx.strokeStyle = gradient;
        ctx.fillStyle = gradient;

        ctx.shadowColor = "rgba(0, 0, 0, 0.5)";
        ctx.shadowBlur = 5;
        ctx.shadowOffsetX = 1;
        ctx.shadowOffsetY = 1;

        if (!this.popping) {
          ctx.beginPath();
          ctx.lineWidth = 2;
          ctx.arc(0, 0, this.radius - 3, 0, Math.PI * 1.5, true);
          ctx.stroke();

          ctx.beginPath();
          ctx.arc(0, 0, this.radius, 0, Math.PI * 2, false);
          ctx.stroke();
        }

        ctx.restore();

        this.lines.forEach((line) => {
          line.render();
        });
      },
    };

    for (let i = 0; i < popLines; i++) {
      const tempLine = createLine(bubble, i);
      bubble.lines.push(tempLine);
    }

    return bubble;
  };

  const createLine = (bubble: Bubble, index: number): Line => {
    return {
      x: bubble.position.x,
      y: bubble.position.y,
      lineLength: 0,
      popDistance: 0,
      popDistanceReturn: 0,
      inversePop: false,
      popping: false,
      resetValues() {
        this.lineLength = 0;
        this.popDistance = 0;
        this.popDistanceReturn = 0;
        this.inversePop = false;
        this.popping = false;
        this.updateValues();
      },
      updateValues() {
        const angle = (2 * Math.PI * index) / bubble.lines.length;
        this.x = bubble.position.x + (bubble.radius + this.popDistanceReturn) * Math.cos(angle);
        this.y = bubble.position.y + (bubble.radius + this.popDistanceReturn) * Math.sin(angle);
        this.lineLength = bubble.radius * this.popDistance;
      },
      render() {
        const ctx = canvasRef.current?.getContext("2d");
        if (!ctx) return;

        if (this.popping) {
          if (this.popDistance < popDistance && !this.inversePop) {
            this.popDistance += 0.1; // Speed up pop distance
          } else {
            this.inversePop = true;
            this.popDistance -= 0.05; // Slow down return
            this.popDistanceReturn += 0.5; // Increase return distance
          }

          if (this.popDistance <= 0) {
            this.popping = false;
            this.resetValues();
          }
        }

        ctx.beginPath();
        ctx.strokeStyle = "#8bc9ee";
        ctx.lineWidth = 2;
        ctx.moveTo(this.x, this.y);
        const endX = this.x + this.lineLength * Math.cos((2 * Math.PI * index) / bubble.lines.length);
        const endY = this.y + this.lineLength * Math.sin((2 * Math.PI * index) / bubble.lines.length);
        ctx.lineTo(endX, endY);
        ctx.stroke();
      },
    };
  };

  const handleClick = (e: MouseEvent) => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const rect = canvas.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    bubbles.forEach((bubble) => {
      const dx = x - bubble.position.x;
      const dy = y - bubble.position.y;
      if (Math.sqrt(dx * dx + dy * dy) < bubble.radius * 2.5) {
        bubble.pop();
      }
    });
  };

  const handleMouseMove = (e: MouseEvent) => {
    const canvas = canvasRef.current;
    if (!canvas) return;
  
    const rect = canvas.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
  
    bubbles.forEach((bubble) => {
      const dx = x - bubble.position.x;
      const dy = y - bubble.position.y;
      if (Math.sqrt(dx * dx + dy * dy) < bubble.radius) {
        if (!bubble.popping) {
          bubble.pop();  // Trigger pop effect
        }
      }
    });
  };

  const animate = () => {
    const canvas = canvasRef.current;
    const ctx = canvas?.getContext("2d");
    if (!canvas || !ctx) return;

    ctx.clearRect(0, 0, canvas.width, canvas.height);

    bubbles.forEach((bubble) => {
      bubble.position.x =
        Math.sin(bubble.count / bubble.distanceBetweenWaves) * 50 + bubble.xOff;
      bubble.position.y = bubble.count;
      bubble.render();

      if (bubble.count < 0 - bubble.radius) {
        bubble.count = canvas.height + bubble.yOff;
      } else {
        bubble.count -= bubbleSpeed;
      }
    });

    requestAnimationFrame(animate);
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    canvas.width = canvas.offsetWidth;
    canvas.height = canvas.offsetHeight;

    const newBubbles = [];
    for (let i = 0; i < bubbleCount; i++) {
      newBubbles.push(createBubble());
    }
    setBubbles(newBubbles);
  }, []);

  useEffect(() => {
    if (bubbles.length > 0) {
      requestAnimationFrame(animate);
    }
  }, [bubbles]);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    canvas.addEventListener("click", handleClick);
    canvas.addEventListener("mousemove", handleMouseMove);

    return () => {
      canvas.removeEventListener("click", handleClick);
      canvas.removeEventListener("mousemove", handleMouseMove);
    };
  }, [bubbles]);

  return <canvas ref={canvasRef} style={{ width: "100%", height: "100%", cursor: 'help' }} />;
};

export default BubbleBackground;
