import React, { useContext, useState } from "react";
import Header from "../Header/Header";
import {
  ArrowRigthIcon,
  BoxContainer,
  BoxLinks,
  BoxNoise,
  LabelItemsMenu,
  LiItem,
  MainContainer,
  MenuContent,
  MenuItems,
  MenuList,
  TextLinks,
} from "./layout.styles";
import CanvasBackground from "../CanvasBackground/CanvasBackground";
import { useNavigate, useLocation } from "react-router-dom";
import { ThemeContext } from "../../utils/themeContext";
import BubbleBackground from "../BubbleBackground/BubbleBackground";

const Layout = ({ children }: { children: React.ReactNode }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { isLightMode } = useContext(ThemeContext);

  const menuItems = [
    { label: "Home", href: "/" },
    { label: "Work", href: "/work" },
    { label: "About", href: "/about" },
    { label: "Contact", href: "/contact" },
  ];

  const links = [
    {
      label: "Linkedin",
      href: "https://www.linkedin.com/in/dev-jacobojimenez/",
    },
    { label: "Github", href: "https://github.com/ImJac0b" },
  ];

  const handleNavigation = (href: string) => {
    navigate(href);
    setIsMenuOpen(false);
  };

  return (
    <div>
      <Header isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />
      <MainContainer isLightMode={isLightMode}>
        {isLightMode ? (
          <BubbleBackground />
        ) : (
          <CanvasBackground url={location.pathname} />
        )}
        {isMenuOpen ? (
          <BoxContainer>
            <MenuContent>
              <MenuItems>
                <nav>
                  <MenuList>
                    {menuItems.map((menuItem, index) => (
                      <LiItem key={index} index={index}>
                        <LabelItemsMenu
                          isLightMode={isLightMode}
                          variant="h1"
                          onClick={() =>
                            menuItem.href === "/work"
                              ? {}
                              : handleNavigation(menuItem.href)
                          }
                          active={location.pathname === menuItem.href}
                          disabled={menuItem.href === "/work" && true}
                        >
                          {menuItem.label}
                        </LabelItemsMenu>
                      </LiItem>
                    ))}
                  </MenuList>
                  <BoxLinks>
                    {links.map((link, index) => (
                      <TextLinks
                        isLightMode={isLightMode}
                        key={index}
                        index={index}
                        href={link.href}
                        target="_blank"
                      >
                        <ArrowRigthIcon isLightMode={isLightMode} />
                        {link.label}
                      </TextLinks>
                    ))}
                  </BoxLinks>
                </nav>
                <div></div>
              </MenuItems>
            </MenuContent>
          </BoxContainer>
        ) : (
          <>{children}</>
        )}
      </MainContainer>
      <BoxNoise></BoxNoise>
    </div>
  );
};

export default Layout;
