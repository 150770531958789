import styled from "@emotion/styled";
import { Box, Typography } from "@mui/material";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

export const Container = styled(Box)`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  position: absolute;
  z-index: 2;
  pointer-events: none;
`;

export const Content = styled(Box)`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  padding: 15%;
  gap: 15px;

  @media (max-width: 912px) and (max-height: 1368px) {
    justify-content: center;
    padding: 10%;
  }

  @media (max-width: 853px) and (max-height: 1280px) {
    justify-content: center;
    padding: 7%;
  }

  @media (max-width: 540px) and (max-height: 720px) {
    justify-content: center;
    padding: 5%;
  }

  @media (max-width: 430px) and (max-height: 932px),
    (max-width: 412px) and (max-height: 915px),
    (max-width: 412px) and (max-height: 914px) {
    justify-content: center;
    padding: 7%;
  }
`;

export const TitleBox = styled(Box)<{
  isLightMode: boolean;
}>`
  display: flex;
  width: 100%;
  border-bottom: 2px solid #f3f2f9;
  border-bottom: ${(props) => props.isLightMode && "2px solid #606887"};
`;

export const Title = styled(Typography)<{
  isLightMode: boolean;
}>`
  && {
    width: 100%;
    font-family: "Montserrat", sans-serif;
    color: #f3f2f9;
    color: ${(props) => props.isLightMode && "#606887"};
    font-size: 3rem;
    text-transform: uppercase;
    font-variation-settings: "wght" 800, "wdth" 125;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
    pointer-events: all;
    letter-spacing: -0.015em;

    ::selection {
      background-color: #f3f2f9;
      background-color: ${(props) => props.isLightMode && "#78ffd1"};
      color: #aab2d1;
      color: ${(props) => props.isLightMode && "#606887"};
      text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
    }

    @media (max-width: 540px) and (max-height: 720px),
      (max-width: 430px) and (max-height: 932px),
      (max-width: 412px) and (max-height: 915px),
      (max-width: 412px) and (max-height: 914px) {
      font-size: 2rem;
    }
  }
`;

export const DescriptionBox = styled(Box)`
  display: flex;
  width: 70%;

  @media (max-width: 853px) and (max-height: 1280px),
    (max-width: 540px) and (max-height: 720px),
    (max-width: 430px) and (max-height: 932px),
    (max-width: 412px) and (max-height: 915px),
    (max-width: 412px) and (max-height: 914px) {
    width: 100%;
  }
`;

export const Description = styled(Typography)<{
  isLightMode: boolean;
}>`
  && {
    font-size: 1.25rem;
    font-family: "Montserrat", sans-serif;
    color: #f3f2f9;
    color: ${(props) => props.isLightMode && "#606887"};
    font-variation-settings: "wght" 500, "wdth" 100;
    pointer-events: all;
    letter-spacing: -0.015em;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
    line-height: 1.2;

    ::selection {
      background-color: #f3f2f9;
      background-color: ${(props) => props.isLightMode && "#78ffd1"};
      color: #aab2d1;
      color: ${(props) => props.isLightMode && "#606887"};
      text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
    }

    @media (max-width: 540px) and (max-height: 720px),
      (max-width: 430px) and (max-height: 932px),
      (max-width: 412px) and (max-height: 915px),
      (max-width: 412px) and (max-height: 914px) {
      font-size: 1rem;
    }
  }
`;

export const BoxLink = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  text-align: center;
  flex-direction: row;
  padding: 16px;
  gap: 20px;
  padding-left: 0px;
`;

export const TextLink = styled.a<{
  isLightMode: boolean;
}>`
  && {
    font-size: 1.25rem;
    font-variation-settings: "wght" 500, "wdth" 125;
    text-transform: lowercase;
    text-decoration: none;
    color: #f3f2f9;
    color: ${(props) => props.isLightMode && "#606887"};
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
    display: flex;
    gap: 8px;
    align-items: center;
    position: relative;
    pointer-events: all;
    font-family: "Montserrat", sans-serif;
    line-height: 1.168;
    cursor: pointer;

    ::selection {
      background-color: #f3f2f9;
      background-color: ${(props) => props.isLightMode && "#78ffd1"};
      color: #aab2d1;
      color: ${(props) => props.isLightMode && "#606887"};
      text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
    }

    &::after {
      content: "";
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: -8px;
      width: 0;
      height: 4px;
      background-color: #f3f2f9;
      background-color: ${(props) => props.isLightMode && "#606887"};
      transition: width 0.3s ease-in-out;
    }

    :hover {
      &::after {
        width: 100%;
        left: 0;
        transform: none;
      }
    }

    @media (max-width: 540px) and (max-height: 720px),
      (max-width: 430px) and (max-height: 932px),
      (max-width: 412px) and (max-height: 915px),
      (max-width: 412px) and (max-height: 914px) {
      font-size: 1rem;
      line-height: 1;
    }
  }
`;

export const ArrowDownIcon = styled(ArrowDownwardIcon)<{
  isLightMode: boolean;
}>`
  && {
    color: #f3f2f9;
    color: ${(props) => props.isLightMode && "#606887"};

    @media (max-width: 412px) and (max-height: 915px) {
      font-size: 1rem;
    }

    @media (max-width: 540px) and (max-height: 720px),
      (max-width: 430px) and (max-height: 932px),
      (max-width: 412px) and (max-height: 915px),
      (max-width: 412px) and (max-height: 914px) {
      font-size: 1rem;
    }
  }
`;
