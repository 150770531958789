import React, { useEffect, useRef } from "react";
import { createNoise3D } from "simplex-noise";

class HSLA {
  constructor(public h = 0, public s = 0, public l = 0, public a = 0) {}
  toString() {
    return `hsla(${this.h}, ${this.s * 100}%, ${this.l * 100}%, ${this.a})`;
  }
}

class Particle {
  public x: number;
  public y: number;
  public color: HSLA;
  public pastX: number;
  public pastY: number;
  public offsetX: number;
  public offsetY: number;
  public alpha: number;

  constructor(
    x: number = 0,
    y: number = 0,
    color: HSLA = new HSLA(),
    alpha: number = 1
  ) {
    this.x = x;
    this.y = y;
    this.color = color;
    this.pastX = this.x;
    this.pastY = this.y;
    this.offsetX = Math.random() * 400 - 200;
    this.offsetY = Math.random() * 400 - 200;
    this.alpha = alpha;
  }

  updatePosition(
    centerX: number,
    centerY: number,
    noise3D: (x: number, y: number, z: number) => number,
    zoff: number
  ) {
    const noiseFactor = 1.5;
    this.x =
      centerX +
      this.offsetX * noiseFactor * noise3D(this.x / 100, this.y / 100, zoff);
    this.y =
      centerY +
      this.offsetY * noiseFactor * noise3D(this.x / 100, this.y / 100, zoff);
  }
}

interface CanvasBackgroundProps {
  isMenuOpen?: boolean;
  url?: string;
  isLightMode?: boolean
}

const CanvasBackground: React.FC<CanvasBackgroundProps> = ({
  url
}) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    if (true) {
      const canvas = canvasRef.current;
      const context = canvas.getContext("2d");
      let screenWidth = (canvas.width = window.innerWidth);
      let screenHeight = (canvas.height = window.innerHeight);
      let particles: Particle[] = [];
      let zoff = 0;
      const desiredColor = new HSLA(228, 0.2, 0.1, 1);
      let noise3D = createNoise3D();

      const Configs = {
        backgroundColor: "transparent",
        particleNum: 600,
        step: 2,
        base: 600,
        zInc: 0.0005,
        clickRadius: 100,
      };

      const initParticle = (p: Particle) => {
        p.x = Math.random() * screenWidth;
        p.y = Math.random() * screenHeight;
        p.color = new HSLA(desiredColor.h, desiredColor.s, desiredColor.l, 0);
        return p;
      };

      const getNoise = (x: number, y: number, z: number) => {
        return noise3D((x / Configs.base) * 1.75, (y / Configs.base) * 1.75, z);
      };

      const updateParticles = () => {
        const step = Configs.step;
        const len = particles.length;
        context.globalCompositeOperation = "source-over";

        for (let i = 0; i < len; i++) {
          const p = particles[i];
          p.pastX = p.x;
          p.pastY = p.y;
          const angle = Math.PI * 6 * getNoise(p.x, p.y, zoff);
          p.x += Math.cos(angle) * step;
          p.y += Math.sin(angle) * step;

          if (p.color.a < 1) p.color.a += 0.001;

          if (
            p.x >= 0 &&
            p.x <= screenWidth &&
            p.y >= 0 &&
            p.y <= screenHeight
          ) {
            context.beginPath();
            context.lineWidth = 2;
            context.strokeStyle = p.color.toString();
            context.moveTo(p.pastX, p.pastY);
            context.lineTo(p.x, p.y);
            context.stroke();
          }

          if (p.x < 0 || p.x > screenWidth || p.y < 0 || p.y > screenHeight) {
            initParticle(p);
          }
        }
      };

      const update = () => {
        context.fillStyle = Configs.backgroundColor;
        context.fillRect(0, 0, screenWidth, screenHeight);
        updateParticles();
        zoff += Configs.zInc;
        requestAnimationFrame(update);
      };

      const onWindowResize = () => {
        screenWidth = canvas.width = window.innerWidth;
        screenHeight = canvas.height = window.innerHeight;
        context.lineWidth = 2;
        context.lineCap = context.lineJoin = "round";
      };

      const onCanvasClick = (e: MouseEvent) => {
        const rect = canvas.getBoundingClientRect();
        const clickX = e.clientX - rect.left;
        const clickY = e.clientY - rect.top;

        particles.forEach((p) => {
          const distance = Math.sqrt((p.x - clickX) ** 2 + (p.y - clickY) ** 2);
          if (distance < Configs.clickRadius) {
            const randomHue = Math.random() * 360;
            p.color = new HSLA(randomHue, 1, 0.5, 1);
          }
        });
      };

      for (let i = 0; i < Configs.particleNum; i++) {
        particles.push(initParticle(new Particle()));
      }

      window.addEventListener("resize", onWindowResize);
      canvas.addEventListener("click", onCanvasClick);

      update();

      setTimeout(() => {
        fadeOut();
      }, 60000);

      const fadeOut = () => {
        const fadeInterval = setInterval(() => {
          context.globalAlpha -= 0.01;
          context.fillStyle = Configs.backgroundColor;
          context.fillRect(0, 0, screenWidth, screenHeight);
          if (context.globalAlpha <= 0) {
            clearInterval(fadeInterval);
            restartAnimation();
          }
        }, 16);
      };

      const restartAnimation = () => {
        context.globalAlpha = 1;
        zoff = 0;
        update();
      };

      return () => {
        window.removeEventListener("resize", onWindowResize);
        canvas.removeEventListener("click", onCanvasClick);
      };
    }
  }, []);

  return (
    <canvas
      ref={canvasRef}
      style={{ cursor: "help", filter: url === '/' ? "blur(1.5px)" : "blur(6px)" , width: '100%', height: '100vh' }}
      className="canvas-background"
    />
  );
};

export default CanvasBackground;
