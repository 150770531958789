import styled from "@emotion/styled";
import { Box, Typography } from "@mui/material";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";

export const MainContainer = styled("main")<{ isLightMode: boolean }>`
  background-color: #2b2b33;
  background-color: ${(props) => props.isLightMode && "#f3f2f9"};
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
`;

export const MainLayout = styled("div")<{ isLightMode: boolean }>`
  background-color: #2b2b33;
  background-color: ${(props) => props.isLightMode && "#f3f2f9"};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  margin: 0;
  padding: 0;
`;

export const BoxNoise = styled(Box)`
  position: fixed;
  z-index: 9999;
  top: -300%;
  left: -150%;
  height: 600%;
  width: 600%;
  -webkit-animation: grain 7s steps(10) infinite;
  animation: grain 7s steps(10) infinite;
  background-image: url(${process.env.PUBLIC_URL}/images/noise.png);
  background-size: 200px;
  background-repeat: repeat;
  opacity: 0.2;
  pointer-events: none;

  @keyframes grain {
    0% {
      transform: translate(0, 0);
    }
    10% {
      transform: translate(-5%, -5%);
    }
    20% {
      transform: translate(-10%, 5%);
    }
    30% {
      transform: translate(5%, -10%);
    }
    40% {
      transform: translate(10%, 0);
    }
    50% {
      transform: translate(5%, 5%);
    }
    60% {
      transform: translate(0, 10%);
    }
    70% {
      transform: translate(-5%, 0);
    }
    80% {
      transform: translate(-10%, -5%);
    }
    90% {
      transform: translate(0, -10%);
    }
    100% {
      transform: translate(0, 0);
    }
  }
`;

export const BoxContainer = styled(Box)`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  position: absolute;
  z-index: 2;
  pointer-events: none;
`;

export const MenuContent = styled(Box)`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

export const MenuItems = styled(Box)`
  display: flex;
  justify-content: space-around;
  width: 100%;
`;

export const MenuList = styled.ol`
  counter-reset: counter;
`;

export const LiItem = styled.li<{ index: number }>`
  list-style: none;
  opacity: 0;
  display: flex;
  animation: fadeIn 1s forwards;
  animation-delay: ${(props) => props.index * 0.1}s;

  @keyframes fadeIn {
    to {
      opacity: 1;
    }
  }
`;

export const LabelItemsMenu = styled(Typography)<{
  active: boolean;
  isLightMode: boolean;
  disabled: boolean;
}>`
  && {
    font-size: 4.5rem;
    color: ${(props) => (props.active ? "#aab2d1" : "#f3f2f9")};
    color: ${(props) =>
      props.isLightMode && (props.active ? "#575768" : "#606887")};
    font-weight: 600;
    letter-spacing: 0.5rem;
    font-family: "Montserrat", sans-serif;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
    pointer-events: all;
    text-transform: uppercase;
    font-variation-settings: "wght" 650, "wdth" 125;
    cursor: pointer;

    &:hover {
      color: #aab2d1;
      color: ${(props) => props.isLightMode && "#575768"};
    }

    ::selection {
      background-color: #f3f2f9;
      background-color: ${(props) => props.isLightMode && "#78ffd1"};
      color: #2b2b33;
      color: ${(props) => props.isLightMode && "#606887"};
    }

    ::before {
      content: "0 " counter(counter);
      font-size: 1rem;
      letter-spacing: -0.1em;
      margin-right: 1rem;
      counter-increment: counter;
    }

    @media (max-width: 540px) and (max-height: 720px) {
      font-size: 3rem;

      ::before {
        font-size: 1rem;
        font-variation-settings: normal;
      }
    }

    @media (max-width: 430px) and (max-height: 932px),
      @media (max-width: 412px) and (max-height: 915px),
      @media (max-width: 412px) and (max-height: 914px) {
      font-size: 2.5rem;

      ::before {
        font-size: 1rem;
        font-variation-settings: normal;
      }
    }

    ${({ disabled }) =>
      disabled &&
      `
        filter: opacity(0.3);
      `};
  }
`;

export const BoxLinks = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  text-align: center;
  flex-direction: row;
  padding: 16px;
  gap: 20px;
`;

export const TextLinks = styled.a<{ index: number; isLightMode: boolean }>`
  && {
    font-size: 1.25rem;
    font-variation-settings: "wght" 500, "wdth" 125;
    text-transform: lowercase;
    text-decoration: none;
    color: #f3f2f9;
    color: ${(props) => props.isLightMode && "#606887"};
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
    display: flex;
    gap: 8px;
    align-items: center;
    position: relative;
    pointer-events: all;
    font-family: "Montserrat", sans-serif;
    line-height: 1.168;
    opacity: 0;
    animation: fadeIn 1s forwards;
    animation-delay: ${(props) => props.index * 0.5}s;

    ::selection {
      background-color: #f3f2f9;
      background-color: ${(props) => props.isLightMode && "#78ffd1"};
      color: #2b2b33;
      color: ${(props) => props.isLightMode && "#606887"};
    }

    &::after {
      content: "";
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: -8px;
      width: 0;
      height: 4px;
      background-color: #f3f2f9;
      background-color: ${(props) => props.isLightMode && "#606887"};
      transition: width 0.3s ease-in-out;
    }

    :hover {
      &::after {
        width: 100%;
        left: 0;
        transform: none;
      }
    }

    @keyframes fadeIn {
      to {
        opacity: 1;
      }
    }

    @media (max-width: 540px) and (max-height: 720px) {
      font-size: 1rem;
    }

    @media (max-width: 430px) and (max-height: 932px),
      @media (max-width: 412px) and (max-height: 915px),
      @media (max-width: 412px) and (max-height: 914px) {
      font-size: 1rem;
    }
  }
`;

export const ArrowRigthIcon = styled(ArrowOutwardIcon)<{
  isLightMode: boolean;
}>`
  && {
    color: #f3f2f9;
    color: ${(props) => props.isLightMode && "#606887"};

    @media (max-width: 540px) and (max-height: 720px) {
      font-size: 1rem;
    }

    @media (max-width: 430px) and (max-height: 932px),
      @media (max-width: 412px) and (max-height: 915px),
      @media (max-width: 412px) and (max-height: 914px) {
      font-size: 1rem;
    }
  }
`;
